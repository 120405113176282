<template>
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card flat min-height="270px" class="pt-6 pb-0 px-4">
        <v-card-text class="pb-1">
          <v-alert
                   outlined
                   type="success">
            <p class="subtitle-2 font-weight-light grey--text text--darken-2">
              Введіть платника послуг. Ця інформація допоможе провести ідентифікацію ваших платежів
            </p>
          </v-alert>
        </v-card-text>
        <v-card-text class="pt-1">
          <v-text-field
            label="Платник"
            prepend-icon=""
            append-icon="mdi-circle-edit-outline"
            required
            outlined
            hide-details
            v-model.trim="payer_edited"
          />
        </v-card-text>
        <v-card-text class="text-right mb-0 pb-0">
          <v-btn text small depressed class="mr-1 grey--text text--darken-1 font-weight-light" @click="dialog = false">Відмінити</v-btn>
          <v-btn small depressed color="success font-weight-light" @click="editPayer">ОК</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
    export default {
    name: "DetailEdit",
    props: {
      parentDialog: {
          type: Boolean,
          default() {
              return false;
          }
      },
      useParentDialog: {
          type: Boolean,
          default() {
              return false;
          }
      },
      payer_origin: {
        type: String,
        default() {
            return '';
        }
      },
    },
    model: {
        prop: 'parentDialog',
        event: 'change'
    },
    computed: {
      dialog: {
          get: function () {
              if (this.useParentDialog) {
                  return this.parentDialog
              } else {
                  return this.childDialog
              }
          },
          set: function (value) {
              if (this.useParentDialog) {
                  this.$emit('change', value)
              } else {
                  this.childDialog = value
              }
          }
      },
    },
    data () {
      return {
          payer_edited: this.payer_origin,
          childDialog: false
      }
    },
    methods: {
        editPayer() {
            this.$emit('editPayer', this.payer_edited );
            this.dialog = false;
        }
    },
    watch: {
      payer_origin: {
          handler: function () {
              this.payer_edited = this.payer_origin;
          },
          immediate: true
      }
    },
    beforeCreate() {
        this.payer_edited = '';
    },
  }
</script>

<style scoped>
  .card-without-radius {
    border-radius: 0 !important;
  }
</style>
