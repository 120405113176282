<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card flat class="pa-2">
      <v-card-title>
        Платіжні реквізити
      </v-card-title>
      <v-divider/>
      <v-card-text class="py-2">
        <v-container fluid class="pa-1">
          <v-row no-gutters class="font-weight-regular body-1">
            <v-col md="4" class="mb-1"><span class="green--text text--darken-1">Назва організації:</span></v-col>
            <v-col md="8"><span>{{organization.name}}</span></v-col>
            <v-col md="4" class="mb-1"><span class="green--text text--darken-1">Код за ЄДРПОУ:</span></v-col>
            <v-col md="8">{{organization.code}}</v-col>
            <v-col md="4" class="mb-1"><span class="green--text text--darken-1">Назва банку:</span></v-col>
            <v-col md="8">{{organization.bank}}</v-col>
            <v-col md="4" class="mb-1"><span class="green--text text--darken-1">МФО банку:</span></v-col>
            <v-col md="8">{{organization.bic}}</v-col>
            <v-col md="4"><span class="green--text text--darken-1">№ рахунку:</span></v-col>
            <v-col md="8">{{organization.account}}</v-col>
          </v-row>
        </v-container>

      </v-card-text>
      <v-divider/>
      <v-card-text class="text-right mb-0 pb-0 pr-1">
        <v-btn small depressed class="mr-1 grey--text text--darken-1 font-weight-light" @click="dialog = false">Закрити</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
      name: "OrganizationDetails",
      props: {
          parentDialog: {
              type: Boolean,
              default() {
                  return false;
              }
          },
          useParentDialog: {
              type: Boolean,
              default() {
                  return false;
              }
          },
          service_name: {
              type: String,
              default() {
                  return '';
              }
          },
          organization: {
              type: Object,
              default() {
                  return {};
              }
          },
      },
      model: {
          prop: 'parentDialog',
          event: 'change'
      },
      computed: {
          dialog: {
              get: function () {
                  if (this.useParentDialog) {
                      return this.parentDialog
                  } else {
                      return this.childDialog
                  }
              },
              set: function (value) {
                  if (this.useParentDialog) {
                      this.$emit('change', value)
                  } else {
                      this.childDialog = value
                  }
              }
          }
      },
      data () {
          return {
              childDialog: false
          }
      },
  }
</script>

<style scoped>
  .card-without-radius {
    border-radius: 0 !important;
  }
</style>
