import http from '@/lib/http'

const CART_ADD_URL = '/payment/cart/add';
const CART_ADD_VIEW = '/payment/cart/view';
const CART_CONFIRM = '/payment/cart/confirm';
const CART_REMOVE_URL = '/payment/cart/remove';
const CART_EMPTY_URL = '/payment/cart/empty';
const CART_HISTORY = '/payment/history/view';
const CART_ITEM = '/payment/cart/item';


export const CartAPI = {
  add(payments) {
    return http.post(CART_ADD_URL, payments)
  },
  remove(payments) {
    return http.post(CART_REMOVE_URL, payments)
  },
  empty() {
    return http.post(CART_EMPTY_URL, {})
  },
  view(payload) {
    return http.post(CART_ADD_VIEW, payload)
  },
  item(payload) {
    return http.post(CART_ITEM, payload)
  },
  confirm(payments) {
    return http.post(CART_CONFIRM, payments)
  },
  history(offset, limit) {
    return http.post(CART_HISTORY, {offset, limit})
  }
};
