<template>
  <v-text-field
    :clearable="+custom_sum > 0"
    type="tel"
    novalidate
    class="pay_sum"
    :class="getClass"
    color="success"
    hide-details
    elevation-1
    :solo="$vuetify.breakpoint.smAndDown"
    :ref="`${'sum-' + service}`"
    :disabled="hidden"
    :tabindex="index+1"
    :value="custom_sum"
    @focusin="sumFocus"
    @focusout="sumFocusOut"
    @keydown="decimalFormat($event, $event.target.value)"
    @change.native="inputSum"
    @click:clear="clearSum"
    @autocomplete.stop=""
    validate-on-blur
    data-vv-delay="1000"
  >
  </v-text-field>
</template>

<script>
  export default {
    name: "PayInput",
    // model: {
    //   prop: "sum",
    //   event: "input"
    // },
    props: ["sum", 'hidden', 'index', 'service', 'selected_origin'],
    data() {
      return {
        custom_sum: this.sum,
      }
    },
    computed: {
      getClass() {
        let breakpoint = this.$vuetify.breakpoint.name;

        switch (breakpoint) {
          case 'xs':
            return 'pay_sum_sm pay_sum_xs ml-0';
          case 'sm':
            return 'pay_sum_sm ml-4';
          case 'md':
            return 'pay_sum_max_width ml-auto';
          default:
            return 'pay_sum_max_width ml-auto';
        }
      },
    },
    methods: {
      sumFocus(e) {
        let localSum = e.target.value;

        if (localSum === '0' || localSum === '0.00') {
          this.custom_sum = '';
        }
      },
      sumFocusOut(e) {
        let localSum = e.target.value;

        if (localSum === '' || this.custom_sum === null) {
          localSum = '0';
        }

        this.$nextTick(() => {
          let formattedSum = parseFloat(localSum).toFixed(2);
          if (formattedSum === '0.00') {
            formattedSum = '0'
          }
          this.custom_sum = formattedSum;
          // this.$emit('input', { sum: this.custom_sum, selected: this.selected, index: this.index });
        })
      },
      decimalFormat(e, value) {
        if ((e.keyCode === 9) || (e.keyCode === 13) || (e.keyCode === 8)) {
          return true;
        }

        if (e.shiftKey) e.preventDefault();

        let charCode = (e.which) ? e.which : event.keyCode;
        let localValue = '' + value;
        let keyNumbers = [48,49,50,51,52,53,54,55,56,57,96,97,98,99,100,101,102,103,104,105];
        let keySeparators = [110, 188, 190];
        let KeySeparatorChars = ['.', ',', '.'];
        let KeyNumber = false;
        let keySeparartor = false;
        // let currentChar = e.key;

        if (keyNumbers.includes(charCode)) {
          let newlocalValue = localValue;
          const separatorPosition = newlocalValue.indexOf('.');
          if (separatorPosition !== -1) {
            if (newlocalValue.substr(separatorPosition).length <= 2) {
              return true;
            } else {
              e.preventDefault();
              return false;
            }
          } else {
            return true;
          }
        }
        if (keySeparators.includes(charCode)) {
          if (e.key.toLowerCase() === 'ю' || e.key.toLowerCase() === 'б') {
            e.preventDefault();
          }
          keySeparartor = true;

          for (let i in keySeparartor) {
            if (localValue.indexOf(KeySeparatorChars[i]) === -1) {
              localValue += '.';
            } else {
              e.preventDefault();
            }
          }
        }


        if ((!KeyNumber) && (!keySeparartor)) {
          e.preventDefault();
          return false
        }






        // let sum = '' + this.custom_sum;
        // e.preventDefault();
        // let change = false;

        // let keyNumbers = [48,49,50,51,52,53,54,55,56,57,96,97,98,99,100,101,102,103,104,105];
        // let keySeparators = [110, 188, 190];
        //
        // if ((sum.length <= 1) && (e.keyCode === 8)) {
        //   sum = '0';
        //   change = true;
        // }
        //
        // if ((!keyNumbers.includes(e.keyCode))  && (e.keyCode !== 8)) {
        //   this.custom_sum = sum;
        //   change = true;
        // } else {
        //   if ((sum === '0') && (e.keyCode !== 8)) {
        //     sum = e.key;
        //     change = true;
        //   }
        //   if (!this.selected && +sum > 0) {
        //     this.selected = true;
        //   }
        // }
        //
        //
        // if (keySeparators.includes(e.keyCode)) {
        //   if (sum.indexOf('.') === -1 || sum.indexOf(',') === -1) {
        //     sum = sum + '.';
        //     change = true;
        //   }
        // }
        //
        // const separatorIndex = sum.indexOf('.') || sum.indexOf(',');
        //
        // if (separatorIndex !== -1) {
        //   if (separatorIndex !== -1) {
        //     const afterSeparator = sum.length - separatorIndex;
        //
        //     if ((afterSeparator > 2) && (e.keyCode !== 8)) {
        //       sum = sum.slice(0, separatorIndex + 3);
        //       change = true;
        //     }
        //   }
        // }
        //
        // if (change) {
        //   e.preventDefault();
        //   this.custom_sum = sum;
        // }
      },
      inputSum(e) {
        this.$nextTick(() => {
            let a = e.target.value === null ? '0' : e.target.value.toString();
            a = a.replace(',', '.').replace(' ', '');
            // this.custom_sum = a;
            this.$emit('changePayInput', a)
            // this.$emit('input', { sum: this.custom_sum, selected: this.selected, index: this.index });
          }
        );
      },
      clearSum() {
        this.$nextTick(() => {
          this.$emit('changePayInput', '0')
            // this.$emit('input', { sum: this.custom_sum, selected: this.selected, index: this.index });
          }
        );
      }
    },
    beforeDestroy() {
      this.custom_sum = 0;
      this.selected = false;
    }

  }
</script>

<style scoped>


</style>
