<template>
    <v-dialog v-model="dialog" max-width="500">
      <v-card flat min-height="360px" class="px-4 py-7">
        <v-card-text class="pb-1">
          <v-alert
                   outlined
                   type="success">
            <p class="subtitle-2 font-weight-light grey--text text--darken-2">
              По послузі <span class="font-weight-bold body-1">{{ service_name }}</span> відсутній особовий рахунок. Будь ласка вкажіть особовий рахунок, система його збереже і буде використовувати при наступних платежах
            </p>
          </v-alert>
        </v-card-text>
        <v-card-text class="pt-1 pb-2">
          <v-text-field
            label="Особовий рахунок"
            prepend-icon=""
            append-icon="mdi-keyboard-variant"
            required
            outlined
            hide-details
            v-model.trim="person_id_internal_edited"
          />
        </v-card-text>
        <v-card-text class="pt-0 pb-0">
          <v-switch v-model="hidden_edited" color="success" inset :label="`${hidden_edited ? 'Ця послуга мені надається' : 'Не хочу бачити цю послгу'}`" class="pl-1"/>
        </v-card-text>
        <v-card-text class="text-right mb-0 pb-0">
          <v-btn text small depressed class="mr-1 grey--text text--darken-1 font-weight-light" @click="dialog = false">Відмінити</v-btn>
          <v-btn small depressed color="success font-weight-light" @click="editPersonIdInternal">ОК</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
    export default {
    name: "DetailEdit",
    props: {
      parentDialog: {
          type: Boolean,
          default() {
              return false;
          }
      },
      useParentDialog: {
          type: Boolean,
          default() {
              return false;
          }
      },
      service_name: {
          type: String,
          default() {
              return '';
          }
      },
      person_id_internal_origin: {
        type: String,
        default() {
            return '';
        }
      },
      hidden_origin: {
          type: Boolean,
          default() {
              return false;
          }
      },
      item: {
          type: Object,
          default() {
              return {};
          }
      },
    },
    model: {
        prop: 'parentDialog',
        event: 'change'
    },
    computed: {
        dialog: {
            get: function () {
                if (this.useParentDialog) {
                    return this.parentDialog
                } else {
                    return this.childDialog
                }
            },
            set: function (value) {
                if (this.useParentDialog) {
                    this.$emit('change', value)
                } else {
                    this.childDialog = value
                }
            }
        }
    },
    data () {
        return {
            person_id_internal_edited: this.person_id_internal_origin,
            hidden_edited: !this.hidden_origin,
            childDialog: false
        }
    },
    methods: {
        editPersonIdInternal() {
            this.$emit('editPersonIdInternal', { item: this.item, person_id_internal: this.person_id_internal_edited, hidden: !this.hidden_edited });
            this.dialog = false;
        }
    },
      watch: {
        item: {
          immediate: true,
          deep: true,
          handler() {
            this.person_id_internal_edited = this.person_id_internal_origin;
            this.hidden_edited = !this.hidden_origin;
          }
        }
      }
    }
</script>

<style scoped>
  .card-without-radius {
    border-radius: 0 !important;
  }
</style>
