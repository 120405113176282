<template>
  <div class="address">
    <!--(Початок). Контейнер із інформацією-->
    <div class="grey lighten-4">
      <v-container fluid class="container-max-width py-0 grey--text text--darken-2">
        <div class="grey lighten-4 py-4">
          <v-row no-gutters class="flex-nowrap" align="start">
            <span>
              <v-icon
                size="56px"
                color="primary"
                class="pr-4">
                mdi-credit-card
              </v-icon>
            </span>
            <div>
              <h3 class="text-uppercase">Комунальні платежі "Єдина квитанція"</h3>
              <p class="mb-1">Цей сервіс був розроблений задля економії Вашого часу та зручності в оплаті комунальних та інших послуг</p>
            </div>
          </v-row>
        </div>
      </v-container>
    </div>
    <!--(Кінець). Контейнер із інформацією-->

    <!-- (Початок). Основний контейнер для усієї сторінки-->
    <v-container fluid class="container-max-width pt-0">
      <v-card>
        <!-- (Початок). Контейнер адреси, прізвища-->
        <v-card-text class="pb-1">
          <v-row no-gutters  align="center" justify="space-between" >
            <v-col cols="12" xs="12" sm="7" md="8">
              <div>
                <span>Адреса:  </span>
                <h3 class="font-weight-regular grey--text text--darken-2">{{ title }}</h3>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="5" md="4"
                   :class="`${$vuetify.breakpoint.xsOnly ? 'mt-3' : ''}`">
              <div v-if="isAuthenticated">
                <span>Платник</span>
                <h3 class="font-weight-regular grey--text text--darken-2 pointer payer-hover" @click="payerDialog = true" style="min-height: 23px;">{{ fullName || 'Введіть платника' }}</h3>
                <payerEdit
                  v-model="payerDialog"
                  :payer_origin="fullName"
                  :parentDialog="payerDialog"
                  :useParentDialog="true"
                  @editPayer="finishEditFullName">
                </payerEdit>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- (Кінець). Контейнер адреси, прізвища-->

        <!-- (Початок). Контейнер коментаря-->
        <v-card-text class="pt-1 pb-2">
          <p class="mb-1 blue--text text--darken-1">{{ comments }}</p>
        </v-card-text>

        <v-divider/>

        <div v-show="serviceLoading === true">
          <v-tabs
            v-model="tab1"
            background-color="transparent"
            color="success darken-2">
            <v-tab>
              <span>
                Стан розрахунків
              </span>
            </v-tab>

            <v-tab v-if="use_meters">
               <span>
                Покази лічильників
              </span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab1" class="pt-3">
            <v-tab-item>
              <div :class="`${$vuetify.breakpoint.xsOnly ? 'pl-2' : 'pl-4'}`">
                <v-switch v-model="showHidden" hide-details color="success" inset style="padding-left: 3px" :class="`${$vuetify.breakpoint.mdAndUp ? 'pb-2' : 'pb-4'}`">
                  <template v-slot:label>
                    <div>{{ showHiddenLabelText }}<br v-if="$vuetify.breakpoint.xsOnly"><strong class="green--text">{{ showHiddenLabelCounter }}</strong></div>
                  </template>
                </v-switch>
              </div>

              <v-expansion-panels
                class="services-exp-panel"
                accordion
                focusable>
                <!--(Початок) Заголовок таблиці із даними про нарахування-->
                <v-expansion-panel readonly>
                  <v-expansion-panel-header hide-actions class="pa-0">
                    <v-row no-gutters align="center" class="text-md-right text-sm-right body-2 font-weight-light grey--text text--darken-2 pr-4">
                      <v-col cols="12" md="6" sm="7" xs="12" class="text-md-left text-sm-left">
                        <v-row no-gutters align="center"
                               :class="`${$vuetify.breakpoint.xsOnly ? 'layout-title' : ''}`">

                          <span class="hidden-xs-only" style="top: 3px; position: relative;">
                            <v-checkbox hide-details
                                        color="success darken-1"
                                        class="check_box_selected pl-4 pr-3 my-0"
                                        disabled/>
                          </span>
                          <span class="hidden-xs-only"/>
                          <div class="text-md-left text-sm-left hidden-xs-only">
                            <span class="d-block text-no-wrap">Послуга/Особовий</span>
                          </div>

                          <!--XS-->
                          <v-col xs="3" md="3" class="hidden-sm-and-up check_box_selected_xs_container">
                            <span class="d-inline-flex">
                              <v-checkbox hide-details
                                          color="success darken-1"
                                          class="check_box_selected_xs pt-2 my-0"
                                          disabled/>
                            </span>
                          </v-col>
                          <v-col xs="5" md="5" class="hidden-sm-and-up">
                            <span class="d-block pb-1">Послуга</span>
                          </v-col>
                          <v-col xs="4" md="4" class="hidden-sm-and-up ml-auto">
                            <span class="d-block text-right">Особовий</span>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="2" sm="5" xs="12">
                        <v-row no-gutters align="center">
                          <v-col md="6" sm="6" xs="8"
                                 class="text-sm-right"
                                 :class="`${$vuetify.breakpoint.xsOnly ? 'pay_sum_xs mt-0' : ''}`">
                            Місяць
                          </v-col>
                          <v-col md="6" sm="6" xs="4"
                                 class="text-right"
                          >До оплати</v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="2" sm="6" xs="12"
                             class="text-sm-left text-md-right"
                             :class="`
                              ${$vuetify.breakpoint.xsOnly ? 'pay_sum_xs sum-title' : ''}
                              ${$vuetify.breakpoint.smOnly ? 'sum-title' : ''}
                              `"
                      >Сума оплати</v-col>
                      <v-col cols="12" md="2" sm="6" xs="12"/>
                    </v-row>
                  </v-expansion-panel-header>
                </v-expansion-panel>

                <v-expansion-panel readonly v-for="item in 4" :key="item" class="no-transition">
                  <v-expansion-panel-header hide-actions class="px-0 py-2">
                    <v-row dense align="center" justify="space-between" class="text-md-right text-sm-right py-0 pr-4">
                      <v-col cols="12" md="6" sm="7" xs="12">
                        <v-row no-gutters align="center" :class="`${$vuetify.breakpoint.smAndUp ? 'flex-nowrap' : ''}`">
                          <span class="hidden-xs-only">
                            <v-checkbox hide-details
                                        color="success darken-1"
                                        class="check_box_selected pl-4 pr-2 my-0"
                                        disabled
                            />
                          </span>
                          <span class="hidden-xs-only">
                           <v-avatar color="grey" size="26"/>
                          </span>
                          <div class="text-md-left text-sm-left hidden-xs-only">
                            <span class="d-block ml-3">
                              <span>
                                <v-skeleton-loader width="200px" type="text"/>
                              </span>
                              <span class="d-block">
                                <v-skeleton-loader width="300px" type="text"/>
                              </span>
                            </span>
                          </div>
                          <v-col cols="3" xs="3" class="hidden-sm-and-up check_box_selected_xs_container">
                            <span class="d-inline-flex">
                              <v-checkbox hide-details
                                          color="success darken-1"
                                          class="check_box_selected_xs pt-2 my-0"
                                          disabled
                              />
                            </span>
                            <span class="d-inline-flex">
                              <v-avatar color="grey" size="23px" class="ml-2"/>
                            </span>
                          </v-col>
                          <v-col cols="9" xs="9" class="hidden-sm-and-up">
                            <span class="d-block font-weight-regular grey--text text--darken-3 pb-1">
                              <v-skeleton-loader type="text"/>
                            </span>
                          </v-col>
                          <v-col cols="8" xs="8" class="hidden-sm-and-up ml-auto">
                            <span class="d-block font-weight-light body-2 mt-1 mb-0 pay_sum_xs">
                              <v-skeleton-loader width="140px" type="text"/>
                            </span>
                          </v-col>
                          <v-col cols="4" xs="8" class="hidden-sm-and-up ml-auto">
                            <span class="d-block font-weight-light body-2 text-right text-md-left text-sm-left text-lg-left">
                              <v-skeleton-loader width="80px" type="text" class="text-right"/>
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!--(Кінець) Checkbox, icon, назва послуги, особовий рахунок-->

                      <!--(Початок) Checkbox-->
                      <v-col cols="12" md="2" sm="5" xs="12">
                        <v-row no-gutters align="center" justify="space-between">
                          <v-col cols="6" md="6" sm="6" xs="8"
                                 class="text-sm-right"
                                 :class="`${$vuetify.breakpoint.xsOnly ? 'pay_sum_xs' : ''}`"
                          >
                            <v-skeleton-loader :class="`${$vuetify.breakpoint.xsOnly ? '' : 'ml-auto'}`" width="80%" type="text"/>
                          </v-col>
                          <v-col cols="6" md="6" sm="6" xs="4" class="text-right ml-auto">
                            <v-skeleton-loader width="80%" type="text" class="ml-auto"/>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!--(Початок) Checkbox-->

                      <!--(Початок) Checkbox-->
                      <v-col cols="12" md="2" sm="7" xs="12"
                             class="text-right"
                             :class="`${$vuetify.breakpoint.xsOnly ? 'pay_sum_xs' : ''}`">
                        <v-skeleton-loader :width="$vuetify.breakpoint.xsOnly ? '98%' : '50%' " type="text" class="ml-auto"/>
                      </v-col>

                      <v-col cols="12" md="2" sm="5" xs="12"
                             class="text-md-center text-sm-left"
                             :class="`${$vuetify.breakpoint.xsOnly ? 'pay_sum_xs' : ''}`">
                        <v-container fluid class="grid-list-sm ma-0 py-0 px-1">
                          <v-row dense justify="center">
                            <v-col cols="4" md="3" xs="4" class="text-md-right">
                              <v-tooltip top color="success">
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    disabled
                                    color="success"
                                    :text="$vuetify.breakpoint.mdAndUp"
                                    class="body-2"
                                    :class="`${$vuetify.breakpoint.xsOnly ? 'my-2' : ''}`"
                                    :block="$vuetify.breakpoint.smAndDown"
                                    :tile="$vuetify.breakpoint.mdAndUp"
                                    :large="$vuetify.breakpoint.mdAndUp"
                                    :icon="$vuetify.breakpoint.mdAndUp">
                                    <v-icon>mdi-speedometer</v-icon>
                                  </v-btn>
                                </template>
                                <span>Ввести покази лічильника(-ів)</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="4" md="3" xs="4" >
                              <v-tooltip top color="primary">
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    color="primary"
                                    disabled
                                    :text="$vuetify.breakpoint.mdAndUp"
                                    class="body-2"
                                    :class="`${$vuetify.breakpoint.xsOnly ? 'my-2' : ''}`"
                                    :block="$vuetify.breakpoint.smAndDown"
                                    :tile="$vuetify.breakpoint.mdAndUp"
                                    :large="$vuetify.breakpoint.mdAndUp"
                                    :icon="$vuetify.breakpoint.mdAndUp">
                                    <v-icon>mdi-pencil</v-icon>
                                  </v-btn>
                                </template>
                                <span>Вказати особовий рахунок</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="4" md="3" xs="4" class="text-md-left">
                              <v-tooltip top color="success">
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    disabled
                                    color="success"
                                    :text="$vuetify.breakpoint.mdAndUp"
                                    class="body-2"
                                    :class="`${$vuetify.breakpoint.xsOnly ? 'my-2' : ''}`"
                                    :block="$vuetify.breakpoint.smAndDown"
                                    :tile="$vuetify.breakpoint.mdAndUp"
                                    :large="$vuetify.breakpoint.mdAndUp"
                                    :icon="$vuetify.breakpoint.mdAndUp">
                                    <v-icon>mdi-delete-empty</v-icon>
                                  </v-btn>
                                </template>
                                <span v-if="!item.hidden">Приховати рядок із послугою</span>
                                <span v-else>Показати рядок із послугою</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                </v-expansion-panel>
                <!--(Кінець) Рядок даних із даними про нарахування-->
              </v-expansion-panels>
            </v-tab-item>
          </v-tabs-items>
        </div>
        <!--(Початок). Поділ на таби: Нарахування, Покази -->
        <div v-show="serviceLoading === false">
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="success darken-2">
            <!--(Початок). Таб №1(назва) Нарахування -->
            <v-tab>
              <span>
                Стан розрахунків
              </span>
            </v-tab>
            <!--(Кінець). Таб №1(назва) Нарахування -->

            <!--(Початок). Таб №2(назва) Покази -->
            <v-tab v-if="use_meters">
               <span>
                Покази лічильників
              </span>
            </v-tab>
            <!--(Кінець). Таб №2(назва) Покази -->
          </v-tabs>
          <v-tabs-items v-model="tab" class="pt-3">
            <!--(Початок). Таб №1(контент) Нарахування -->
            <v-tab-item>
              <div :class="`${$vuetify.breakpoint.xsOnly ? 'pl-2' : 'pl-4'}`">
                <v-switch v-model="showHidden" hide-details color="success" inset style="padding-left: 3px" :class="`${$vuetify.breakpoint.mdAndUp ? 'pb-2' : 'pb-4'}`">
                  <template v-slot:label>
                    <div>{{ showHiddenLabelText }}<br v-if="$vuetify.breakpoint.xsOnly"><strong class="green--text">{{ showHiddenLabelCounter }}</strong></div>
                  </template>
                </v-switch>
              </div>
              <v-expansion-panels
                class="services-exp-panel"
                accordion
                focusable
                v-model="panel">
                <v-expansion-panel readonly>
                  <v-expansion-panel-header hide-actions class="pa-0">
                    <v-row no-gutters align="center" class="text-md-right text-sm-right body-2 font-weight-light grey--text text--darken-2 pr-4">
                      <v-col cols="12" md="6" sm="7" xs="12" class="text-md-left text-sm-left">
                        <v-row no-gutters align="center"
                               :class="`${$vuetify.breakpoint.xsOnly ? 'layout-title' : ''}`">

                          <span class="hidden-xs-only" style="top: 3px; position: relative;">
                            <v-checkbox hide-details
                                        color="success darken-1"
                                        class="check_box_selected pl-4 pr-3 my-0"
                                        v-model="selected"
                                        @change="changeSelectedAll">
                            </v-checkbox>
                          </span>
                          <span class="hidden-xs-only"/>
                          <div class="text-md-left text-sm-left hidden-xs-only">
                            <span class="d-block text-no-wrap">Послуга/Особовий</span>
                          </div>

                          <!--XS-->
                          <v-col xs="3" md="3" class="hidden-sm-and-up check_box_selected_xs_container">
                            <span class="d-inline-flex">
                              <v-checkbox hide-details
                                          color="success darken-1"
                                          class="check_box_selected_xs pt-2 my-0"
                                          v-model="selected"
                                          @change="changeSelectedAll">
                              </v-checkbox>
                            </span>
                          </v-col>
                          <v-col xs="5" md="5" class="hidden-sm-and-up">
                            <span class="d-block pb-1">Послуга</span>
                          </v-col>
                          <v-col xs="4" md="4" class="hidden-sm-and-up ml-auto">
                            <span class="d-block text-right">Особовий</span>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="2" sm="5" xs="12">
                        <v-row no-gutters align="center">
                          <v-col md="6" sm="6" xs="8"
                                 class="text-sm-right"
                                 :class="`${$vuetify.breakpoint.xsOnly ? 'pay_sum_xs mt-0' : ''}`">
                            Місяць
                          </v-col>
                          <v-col md="6" sm="6" xs="4"
                                 class="text-right"
                          >До оплати</v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="2" sm="6" xs="12"
                             class="text-sm-left text-md-right"
                             :class="`
                              ${$vuetify.breakpoint.xsOnly ? 'pay_sum_xs sum-title' : ''}
                              ${$vuetify.breakpoint.smOnly ? 'sum-title' : ''}
                              `"
                      >Сума оплати</v-col>
                      <v-col cols="12" md="2" sm="6" xs="12"/>
                    </v-row>
                  </v-expansion-panel-header>
                </v-expansion-panel>
                <!--(Кінець) Заголовок таблиці із даними про нарахування-->

                <!--(Початок) Рядок даних із даними про нарахування-->
                <v-expansion-panel readonly v-for="(item, index) in services" :key="index" class="no-transition">
                  <!--(Початок) Заголовок компоненти із даними про нарахування-->
                  <v-expansion-panel-header v-show="showHidden ? true : !item.hidden" hide-actions class="px-0 py-2" :class="`${item.hidden ? 'grey lighten-3' : ''}`">
                    <detail-view-row :rowItem="item" :rowIndex="index" :isAuthenticatedView="isAuthenticatedView" :secret_id="secret_id" :showHidden="showHidden"/>
                  </v-expansion-panel-header>
                  <!--(Кінець) Заголовок компоненти із даними про нарахування-->

                  <v-expansion-panel-content class="py-3">
                      <v-row no-gutters  class="py-0 pt-1 pb-2">
                        <v-col cols="12" md="7" sm="12" xs="12">
                          <v-card flat class="elevation-0 border-card">
                            <v-row no-gutters align="center" class="pt-1 px-2 body-2 font-weight-light grey--text text--darken-2 text-md-right">
                              <v-col :cols="cols(0, 3, 12, 5)" md="3" sm="12" xs="5"
                                     class="text-md-left"
                                     :class="`${$vuetify.breakpoint.xsOnly ? 'pl-0' : 'pl-2'}`"
                              >
                                № лічильника
                              </v-col>
                              <v-col :cols="cols(0, 2, 2, 4)" md="2" sm="2" xs="4"
                                     class="text-md-center"
                                     :class="`${$vuetify.breakpoint.smOnly ? 'pl-2' : ''} ${$vuetify.breakpoint.xsOnly ? 'pl-1' : ''}`">
                                Різниця
                              </v-col>
                              <v-col :cols="cols(0, 2, 2, 3)" md="2" sm="2" xs="3" class="text-md-center text-sm-right text-xs-right">Одн. вим.</v-col>
                              <v-col :cols="cols(0, 2, 4, 5)" md="2" sm="4" xs="5" class="text-sm-right">Попередній</v-col>
                              <v-col :cols="cols(0, 3, 4, 7)" md="3" sm="4" xs="7"
                                     class="text-sm-right"
                                     :class="`${$vuetify.breakpoint.xsOnly ? 'pl-1' : ''}`">
                                Внесений показ
                              </v-col>
                            </v-row>
                            <!--                        <v-divider class="success lighten-1"></v-divider>-->
                            <v-row no-gutters align="center" class="px-2 py-2 text-md-right" v-for="(meter, index) in getMetersByService(item.service.id)" :key="meter.person_id_internal + '-' + index">
                              <v-col :cols="cols(0, 3, 12, 5)" md="3" sm="12" xs="5"
                                     class="text-md-left"
                                     :class="`${$vuetify.breakpoint.xsOnly ? 'pl-0' : 'pl-1'}`"
                              >
                                <div>
                                <span>
                                  <v-icon
                                    size="23px"
                                    color="success"
                                    class="pr-2"
                                  >
                                    mdi-speedometer
                                  </v-icon>
                                </span>
                                  <span>{{ meter.meter_id }}</span>
                                </div>
                              </v-col>
                              <v-col :cols="cols(0, 2, 2, 4)" md="2" sm="2" xs="4" class="text-md-center font-weight-bold">
                                <v-chip small color="success" text-color="white" class="ml-1">
                                  {{ meter.difference }}
                                  <v-icon small right>mdi-swap-vertical</v-icon>
                                </v-chip>
                              </v-col>
                              <v-col :cols="cols(0, 2, 2, 3)" md="2" sm="2" xs="3" class="text-md-center text-sm-right text-xs-right">{{ meter.dimension_unit }}</v-col>
                              <v-col :cols="cols(0, 2, 4, 5)" md="2" sm="4" xs="5"
                                     class="text-sm-right"
                                     :class="`${$vuetify.breakpoint.xsOnly ? 'mt-2' : ''}`">
                                <span :class="`${$vuetify.breakpoint.xsOnly ? 'meters-border-xs-span' : ''}`">{{ meter.reading }}</span>
                              </v-col>
                              <v-col :cols="cols(0, 3, 4, 7)" md="3" sm="4" xs="7"
                                     class="text-sm-right"
                                     :class="`${$vuetify.breakpoint.xsOnly ? 'mt-2' : ''}`">
                                <v-text-field clearable
                                              type="tel"
                                              novalidate
                                              class="green--text pay_sum meter_sum"
                                              :class="`${$vuetify.breakpoint.xsOnly ? 'meter_sum_xs ma-0' : ' ml-auto pl-1'}`"
                                              :solo="$vuetify.breakpoint.xsOnly"
                                              hide-details
                                              elevation-1
                                              v-restrict.number
                                              color="green"
                                              v-model.number="meter.reading_last"
                                              tabindex="index"
                                              @change="changeDifference(meter)">
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="5"/>
                      </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!--(Кінець) Рядок даних із даними про нарахування-->
              </v-expansion-panels>
              <!--(Початок) Акордеон/Рядок із даними-->
              <v-divider/>
              <!--(Початок) Контейнер із кнопками: Оплатити, Повернутися-->
              <v-card-text class="py-3">
                <v-row no-gutters  justify="end">
                  <v-col cols="12" md="12">
                    <h3 class="green--text pay-amount-title" v-if="services">Сума до сплати: {{ sum_pay }} грн.</h3>
                    <v-row no-gutters  justify="start" align="center">
<!--                      <v-menu top :close-on-click="true" v-if="!isAuthenticated">-->
<!--                        <template v-slot:activator="{ on, attrs }">-->
<!--                            <v-btn class="primary body-2 mt-3 mr-3"-->
<!--                                   :disabled="loading || !+sum_pay"-->
<!--                                   :tabindex="+sum_pay > 0 ? services.length + 1 : 0"-->
<!--                                   v-bind="attrs" v-on="on"-->
<!--                            >-->
<!--                              <v-icon left dark>-->
<!--                                {{ isAuthenticated ? 'mdi-cart' : 'mdi-wallet' }}-->
<!--                              </v-icon>-->
<!--                              {{ isAuthenticated ? 'Додати в кошик' : 'Перейти до оплати' }}-->
<!--                          </v-btn>-->
<!--                        </template>-->

<!--                        <v-list class="ma-0 pa-3" rounded>-->
<!--                          <v-list-item @click.stop="" class="grey lighten-3 elevation-3 mb-3" @click="payOperation(1)">-->
<!--                            <v-list-item-icon class="my-1 ma-auto">-->
<!--                              <img :src="`${publicPath}images/payments/Apple_Pay_logo.svg`" alt="apple_pay" height="46" width="46">-->
<!--                            </v-list-item-icon>-->
<!--                          </v-list-item>-->

<!--                          <v-list-item @click.stop="" class="grey lighten-3 elevation-3 mb-3" @click="payOperation(1)">-->
<!--                            <v-list-item-icon class="my-1 ma-auto" >-->
<!--                              <img :src="`${publicPath}images/payments/google_pay.svg`" alt="google_pay" height="46" width="46">-->
<!--                            </v-list-item-icon>-->
<!--                          </v-list-item>-->

<!--                          <v-list-item @click.stop="" class="grey lighten-3 elevation-3" @click="payOperation">-->
<!--                            <span class="ma-auto">Ввести номер картки</span>-->
<!--                          </v-list-item>-->
<!--                        </v-list>-->
<!--                      </v-menu>-->
                      <v-btn class="primary body-2 mt-3 mr-3" :disabled="loading || !+sum_pay"
                             @click="payOperation" :tabindex="+sum_pay > 0 ? services.length + 1 : 0">
                        <v-icon left dark>
                          {{ isAuthenticated ? 'mdi-cart' : 'mdi-wallet' }}
                        </v-icon>
                        {{ isAuthenticated ? 'Додати в кошик' : 'Перейти до оплати' }}
                      </v-btn>

                      <confirmDialog v-model="dialog" :useParentDialog="true" :parentDialog="dialog" :showButton="false" :agreeColor="'info'" @confirmModal="confirmModal">
                        <template v-slot:header>
                          Платіж успішно перенесено в корзину!
                        </template>
                        <template v-slot:content>Платіж зареєстровано в корзині, в якій розміщуються підготовлені до оплати послуги. Ви можете повернутися до головної сторінки, або перейти одразу в корзину.</template>
                        <template v-slot:agree>Корзина</template>
                        <template v-slot:cancel>Головна</template>
                      </confirmDialog>
                      <v-btn class="mt-3 body-2" @click="goBack">Повернутися</v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <!--(Кінець) Контейнер із кнопками: Оплатити, Повернутися-->
            </v-tab-item>
            <!--(Кінець). Таб №1(контент) Нарахування -->

            <!--(Початок). Таб №2(контент) Покази -->
            <v-tab-item v-if="use_meters">
              <v-row no-gutters
                     align="center"
                     justify="space-between"
                     class="text-md-right text-sm-right body-2 font-weight-light grey--text text--darken-2 py-2">
                <v-col :cols="cols(0, 3, 4, 12)" md="3" sm="4" xs="12" class="text-md-left text-sm-left">
                  <v-row no-gutters align="center"
                         :class="`${$vuetify.breakpoint.xsOnly ? 'layout-title' : ''}`">
                    <div class="text-md-left text-sm-left hidden-xs-only pl-3">
                      <span class="d-block font-weight-medium grey--text text--darken-2 text-no-wrap">Послуга/Особовий</span>
                    </div>
                    <v-col cols="3" xs="3" class="hidden-sm-and-up check_box_selected_xs_container"/>
                    <v-col cols="5" xs="5" class="hidden-sm-and-up">
                      <span class="d-block font-weight-medium grey--text text--darken-2 pb-1">Послуга</span>
                    </v-col>
                    <v-col cols="4" xs="4" class="hidden-sm-and-up ml-auto">
                      <span class="d-block font-weight-medium grey--text text--darken-2 text-xs-right mr-4">Особовий</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col :cols="cols(0, 7, 8, 12)" md="7" sm="8" xs="12">
                  <v-row no-gutters align="center" justify="space-between">
                    <v-col :cols="cols(0, 3, 3, 8)" md="3" sm="3" xs="8"
                           class="text-sm-right"
                           :class="`${$vuetify.breakpoint.xsOnly ? 'pay_sum_xs mt-0' : ''}`"
                    >
                      Номер лічильника
                    </v-col>
                    <v-col :cols="cols(0, 3, 3, 4)" md="3" sm="3" xs="4"
                           class="text-xs-right"
                           :class="`${$vuetify.breakpoint.xsOnly ? 'mt-0 pr-4' : ''}`">
                      Різниця
                    </v-col>
                    <v-col :cols="cols(0, 3, 3, 8)" md="3" sm="3" xs="8"
                           :class="`${$vuetify.breakpoint.xsOnly ? 'pay_sum_xs' : ''}`"
                    >Одн. виміру</v-col>
                    <v-col :cols="cols(0, 3, 3, 4)" md="3" sm="3" xs="4"
                           class="text-xs-right text-md-right"
                           :class="`${$vuetify.breakpoint.smOnly ? 'pr-4' : ''} ${$vuetify.breakpoint.xsOnly ? 'pr-4' : ''}`"
                    >Попередній</v-col>
                  </v-row>
                </v-col>
                <v-col :cols="cols(0, 2, 6, 12)" md="2" sm="6" xs="12"
                       class="text-sm-left text-md-right sum-title pr-4"
                       :class="`${$vuetify.breakpoint.xsOnly ? 'pay_sum_xs' : ''}`"
                >Внесений показ</v-col>
                <!--<v-col md="2" sm="6" xs="12"></v-col>-->
              </v-row>
              <v-divider/>
              <v-row no-gutters v-for="(item_meter, index) in meters" :key="index"
                     align="center" justify="space-between"
                     class="text-md-right text-sm-right py-1">
                <v-col :cols="cols(0, 3, 4, 12)" md="3" sm="4" xs="12" v-if="!item_meter.hidden">
                  <v-row no-gutters align="center" class="flex-nowrap">
                      <span class="hidden-xs-only pl-3">
                        <i :class="`${getIcon(item_meter.service.config)} pr-3`"/>
                      </span>
                    <div class="text-md-left text-sm-left hidden-xs-only">
                      <span class="d-block font-weight-regular grey--text text--darken-3 text-no-wrap">{{ item_meter.service.name }}</span>
                      <span class="d-block font-weight-light body-2">{{ item_meter.person_id_internal_text }}</span>
                    </div>
                    <v-col cols="12" xs="3" class="hidden-sm-and-up check_box_selected_xs_container">
                        <span class="d-inline-flex">
                          <i :class="`${getIcon(item_meter.service.config)} pl-2`"/>
                        </span>
                    </v-col>
                    <v-col cols="12" xs="5" class="hidden-sm-and-up">
                      <span class="d-block font-weight-regular grey--text text--darken-3 pb-1">{{ item_meter.service.name }}</span>
                    </v-col>
                    <v-col cols="12" xs="4" class="hidden-sm-and-up ml-auto">
                      <span class="d-block font-weight-light body-2 text-xs-right mr-4">{{ item_meter.person_id_internal_text }}</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col :cols="cols(0, 7, 8, 12)" md="7" sm="8" xs="12" v-if="!item_meter.hidden">
                  <v-row no-gutters   align="center" justify="space-between">
                    <v-col :cols="cols(0, 3, 3, 6)" md="3" sm="3" xs="6"
                           class="text-sm-right"
                           :class="`${$vuetify.breakpoint.xsOnly ? 'pay_sum_xs mt-2' : ''}`"
                    >
                      {{ item_meter.meter_id }}
                    </v-col>
                    <v-col :cols="cols(0, 3, 3, 4)" md="3" sm="3" xs="4"
                           class="text-xs-right"
                           :class="`${$vuetify.breakpoint.xsOnly ? 'pr-4' : ''}`">
                      <v-chip small color="success" text-color="white" class="ml-1 subtitle-1">
                        {{ item_meter.difference }}
                        <v-icon small right>mdi-swap-vertical</v-icon>
                      </v-chip>
                    </v-col>
                    <v-col :cols="cols(0, 3, 3, 2)" md="3" sm="3" xs="2" class="pr-4 text-xs-right">
                      {{ item_meter.dimension_unit }}
                    </v-col>
                    <v-col :cols="cols(0, 3, 3, 12)" md="3" sm="3" xs="12"
                           class="text-xs-right text-md-right"
                           :class="`${$vuetify.breakpoint.smOnly ? 'pr-4' : ''}`"
                    >
                      <div
                        :class="`${$vuetify.breakpoint.xsOnly ? 'mr-4 meters-border-xs pay_sum_xs_ml' : ''}`"
                      >
                        {{ item_meter.reading }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col :cols="cols(0, 2, 6, 12)" md="2" sm="6" xs="12" v-if="!item_meter.hidden"
                       :class="`${$vuetify.breakpoint.xsOnly ? 'mr-4 mt-2' : ''}`">
                  <v-text-field clearable
                                type="tel"
                                novalidate
                                class="pay_sum ml-auto pr-4"
                                :class="`
                                          ${$vuetify.breakpoint.smOnly ? 'pay_sum_sm ml-4 mt-2' : ''}
                                          ${$vuetify.breakpoint.xsOnly ? 'pay_sum_sm pay_sum_xs ml-0' : ''}
                                          `"
                                color="success"
                                hide-details
                                elevation-1
                                :solo="$vuetify.breakpoint.smAndDown"
                                v-model.number="item_meter.reading_last"
                                @change="changeDifference(item_meter)"
                                @click:clear ="changeDifference(item_meter)"
                                v-restrict.number
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-divider/>
              <v-card-text class="py-3">
                <v-row no-gutters  justify="start" align="center">
                  <v-btn class="primary body-2 mt-1 mr-3">
                    <v-icon left>
                      mdi-cash
                    </v-icon>
                    Передати покази
                  </v-btn>
                  <v-btn class="mt-1 body-2" @click="goBack">Повернутися</v-btn>
                </v-row>
              </v-card-text>
            </v-tab-item>
            <!--(Кінець). Таб №2(контент) Покази -->
          </v-tabs-items>
        </div>

        <!--(Кінець). Поділ на таби: Нарахування, Покази -->
      </v-card>
    </v-container>

    <div class="grey lighten-4" v-if="!isAuthenticated">
      <v-container fluid class="container-max-width py-0 grey--text text--darken-2">
        <v-alert border="left"
                 colored-border
                 type="info"
                 elevation="2">
          <p class="subtitle-1 font-weight-light">Для використання усіх можливостей сервісу, будь ласка <router-link :to="{name: 'registration', query: {schema: $route.params.schema, secret_id: $route.params.secret_id}}"><b> зареєструйтеся</b></router-link> або <router-link :to="{name: 'login'}"><b>увійдіть</b></router-link>. Це дозволить Вам не витрачати час на пошук своєї адреси, надасть можливість додавання інших адрес, використання нагадувань про оплату та використання довільних платіжних шаблонів (податки, садки тощо)</p>
        </v-alert>
      </v-container>
    </div>
  </div>
</template>

<script>
  import {CHANGE_CART_COUNT} from '@/store/modules/cart/mutation-types';
  import {SET_PAYMENTS, CLEAR_PAYMENTS} from '@/store/modules/payments/mutation-types';
  import {FETCH_DATA, CLEAR_DATA, TOGGLE_SELECTED} from '../../store/modules/detail/mutation-types'
  import {getIcon, addClassToNumber, cols,  assignServices, assignMeters, getAddressTitle, checkout, google_pay } from "@/utils";
  import {CartAPI} from '@/api/cart'
  import {CardAPI} from '@/api/tasCard'
  import AccountAPI from '@/api/account'
  import AddressAPI from '@/api/address'
  import { map, assign, clone } from 'lodash'

  import DetailViewRow from "@/components/global/DetailViewRow";
  import confirmDialog from "@/components/global/confirmDialog";
  import payerEdit from "@/components/global/PayerEdit";
  import {mapGetters} from 'vuex';

  // const AUTOFOCUS = false;
  const USE_METERS = false;

  export default {
    name: 'DetailView',
    props: {
      dataArray: {
        type: Object,
        required: false
      },
    },
    data() {
      return {
        comments: '',
        panel: null,
        selected: false,
        cardItems: [],
        tab: null,
        tab1: null,
        dialog: false,
        payerDialog: false,
        loading: false,
        showHidden: false,
        directPayDialog: false,
        directPayAddSuccess: false,
        directPaySuccess: false,
        directPayId: '',
        use_meters: USE_METERS,
        person: {},
        meters: [],
        title: '',
        fullName: '',
        schema: '',
        secret_id: '',
        address_id: '',
        address: [],
        serviceLoading: true,
        publicPath: process.env.BASE_URL
      }
    },
    computed: {
      ...mapGetters([
        'authUser',
      ]),
      sum_pay() {
        return this.$store.getters.detailAmount;
      },
      services() {
        return this.$store.getters.detailData;
      },
      payments() {
        if (this.services) {
          let selectedServices = this.services.filter(item => item.selected && +item.sum > 0);
          let paymentsList = {payments: null};
          // let schema = this.dataArray.schema ? this.dataArray.schema : this.$route.params.schema;
          // const address_id = this.isAuthenticatedView ? this.dataArray.secret_id : this.dataArray.address.secret_id;

          paymentsList.payments = map(selectedServices, (item) => {
            return assign({}, {
              schema: this.schema,
              address_id: this.address_id,
              template_id: null,
              service_id: item.service.id,
              person_id_internal: item.person_id_internal,
              person_id_internal_text: item.person_id_internal_text,
              sum: parseInt((parseFloat(item.sum) * 100).toFixed(0))
            })
          });

          return paymentsList
        } else {
          this.$store.dispatch(CLEAR_PAYMENTS);
          return undefined;
        }
      },
      isAuthenticated() {
        return this.$store.getters.isAuthenticated;
      },
      isAuthenticatedView() {
        const parentComponentName = this.$parent.$options.name;
        return parentComponentName === 'AuthenticatedUserView';
      },
      hiddenCounter() {
        return this.$store.getters.detailCounter;
      },
      showHiddenLabelText() {
        const point = this.hiddenCounter ? ',' : '';
        return this.showHidden ? 'Показувати приховані послуги' + point : 'Не показувати приховані послуги' + point;
      },
      showHiddenLabelCounter() {
        return this.hiddenCounter ? ' приховано: ' + this.hiddenCounter : '';
      }
    },
    methods: {
      checkout,
      google_pay,
      async fetchData() {
        try {
          let short = false;
          this.schema = this.$route.query['schema'] ||  this.$route.params['schema'];
          if (!this.schema) {
            let pArr = this.$route.path.split('/');
            if (pArr.length > 1) {
              this.schema = pArr[1];
              short = true;
            }
          }
          this.secret_id = this.$route.query['secret_id'] || this.$route.params['secret_id'];

          if (!this.isAuthenticatedView) {
            let payload;
            if (short) {
              payload = { "schema": this.schema, "secret_id_short": this.secret_id }
            } else {
              payload = { "schema": this.schema, "secret_id": this.secret_id }
            }

            let { data } = await AccountAPI.accountView(payload);
            const services = await assignServices(data.services);
            this.meters = assignMeters(data.meters, data.services);
            // this.sum_pay = getSumPay(data);
            this.title = getAddressTitle(data.person);
            this.full_name = data.person.last_name;
            this.address = data.address;
            this.address_id = this.address.secret_id;
            this.fullName = this.full_name;
            await this.$store.dispatch(FETCH_DATA, services)
            this.serviceLoading = false
          } else {
            let { data } = await AddressAPI.addressView(this.schema, this.secret_id);
            const services = await assignServices(data.services);
            this.meters = assignMeters(data.meters, data.services);
            this.title = data.title || getAddressTitle(data.person);
            this.full_name = data.full_name || data.person.last_name;
            this.address_id = this.secret_id;
            this.fullName = this.full_name;
            await this.$store.dispatch(FETCH_DATA, services)
            this.serviceLoading = false
          }
          // this.address_id = this.isAuthenticatedView ? this.secret_id : this.address.secret_id;
        } catch (e) {
          this.serviceLoading = false;
          this.$snackbar("Помилка одержання адреси", 'error')
        }
      },
      async updateDirectAddSuccess(value) {
        this.directPayAddSuccess = value;
      },
      async updateDirectPaySuccess(value) {
        this.directPaySuccess = value;
      },
      async addNewCard(payments) {
        try {
          const {data} = await CardAPI.new({ 'acquiring': 'tas', 'payments': [payments] });
          // console.log(data)
          window.location.href = data;
        } catch (e) {
          this.$snackbar("Відповідь не отримана", 'error')
        }
      },
      notAuthDirectGooglePay(payments) {
        this.checkout(null, [payments])
            .then((payload) => {
              if (payload.id) {
                this.google_pay(payload.id)
                    .then((confirmPayload) => {
                      if (confirmPayload.url) {
                        window.location.href = confirmPayload.url;
                      } else {
                        this.$router.push({ name: 'confirmCheckout', query: { payments: this.payments, pay: true }});
                      }
                    }).catch(() => {
                  this.$snackbar("Помилка підтвердження платежу", 'error')
                })
              }
            });
      },
      async cartAdd(payments, operation=0) {
        this.cardItems = [];
        console.log(operation)
        try {
          const {data} = await CartAPI.add(payments);
          if (this.isAuthenticatedView) {
            await this.$store.dispatch(CHANGE_CART_COUNT, 1);
            this.dialog = true;
          } else {
            this.cardItems = data;
            if (operation === 0) {
              await this.addNewCard(data);
            } else (
              this.notAuthDirectGooglePay(data)
            )
          }
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      },
      async confirmPayCart(paymentId) {
        try {
          let paymentsList = {payments: [paymentId]};
          const {data} = await CartAPI.confirm(paymentsList);
          await this.updateDirectPaySuccess(true);
          if (this.directPaySuccess) {
            if (data) {
              window.location.href = data;
            } else {
              this.$snackbar("Поcилання на оплату не сформовано", 'error')
            }
          }
        } catch (e) {
          this.$snackbar("Помилка підтвердження оплати", 'error')
        }
      },
      async updateFullName(secret_id, full_name) {
        let st = null;
        try {
          await AccountAPI.addressUpdate(secret_id, full_name);
          st = true;
        } catch (e) {
          this.$snackbar("Помилка оновлення платника", 'error')
        }

        return await new Promise(function (resolve, reject) {
          if (st) {
            resolve(full_name);
          } else {
            reject('');
          }
        });
      },
      async directPayPayLink() {
        // get transaction id
        const url = 'https://paylink.com.ua/frame/token/?sid=6ad3a519-fe4b-4028-9ed9-5be541667fcf';
        await this.$store.dispatch(SET_PAYMENTS, this.payments.payments || []);
        window.location.href = url;
      },
      getMetersByService(service_id) {
        return this.meters.filter(meter => meter.service_id === service_id);
      },
      changeDifference(item) {
        if (item.reading_last) {
          item.difference = item.reading_last - item.reading;
        } else {
          item.reading_last = 0;
          item.difference = 0;
        }
      },
      changeSelectedAll() {
        this.$store.dispatch(TOGGLE_SELECTED, this.selected);
      },
      showAccordeonPanel(index) {
        this.panel = index !== this.panel ? index : null
      },
      getSumPay() {
        let sum = 0;

        let cServices = clone(this.services);

        if (cServices) {
          sum = cServices.filter(item => item.selected && !item.hidden).reduce((total, s) => {
            let a = s.sum;
            if (typeof a === 'string') {
              a = a.replace(',', '.').replace(' ', '');
            }
            return total + (+a)
          }, 0);
        }
        this.sum_pay = sum.toFixed(2);
      },
      goBack() {
        this.$router.go(-1)
      },
      payOperation(operation=0) {
        const payments = this.payments;
        this.loading = true;
        if (this.isAuthenticated) {
          if (payments) {
            this.cartAdd(payments, operation);
          } else {
            this.loading = false;
          }
        } else {
          this.loading = false;
          this.cartAdd(payments, 1);
        }
      },
      confirmModal(value) {
        if (value.question) {
          this.$router.push({name: 'cart-view'})
        } else {
          this.$router.push('/')
        }
      },
      finishEditFullName(payload) {
        if (this.fullName !== payload) {
          this.updateFullName(this.$route.params.secret_id, payload).then((value) => {
            this.fullName = value;
          });
        }
      },
      finishDirectPay(confirm) {
        if (confirm) {
          const payments = this.payments;
          this.directPayAddSuccess = false;
          this.directPayDialog = false;
          this.directPaySuccess = false;
          this.directPayId = '';

          this.cartAdd(payments);
        }
        this.loading = false;
      },
      cols,
      getIcon,
      addClassToNumber,
    },
    components: {
      confirmDialog,
      // editDialog,
      payerEdit,
      // organizationDetails,
      // payInput,
      DetailViewRow
    },
    mounted() {
      this.$store.dispatch(CLEAR_DATA);
      this.$store.dispatch(CLEAR_PAYMENTS);
      this.fetchData();
    },
    beforeDestroy() {
      this.$store.dispatch(CLEAR_DATA);
    }
  }
</script>

<style scoped lang="scss">
  a {
    text-decoration: none;
  }
  .font-icon{
    font-size: 22px;
    padding-right: 28px;
    position: relative;
  }
  .container-max-width {
    max-width: 1560px;
  }
  .title-container {
    height: 90px;
    position: relative;

    h2 {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .border-card {
    outline: 1px solid #a4c7a4;
  }
  .border-bottom {
    border-bottom: .5px solid #e0e0e0;
  }
  .meters-border-xs {
    border-radius: 5px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    min-height: 32px;
  }
  .meters-border-xs-span {
    border-radius: 5px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    min-height: 32px;
    width: 96%;
    display: block;
  }
  .check_box_selected {
    padding-top: 0 !important;
  }
  .layout-title {
    height: 21px;
  }
  .check_box_selected_xs {
    width: 20px;
    padding-left: 7px !important;
    margin-right: 8px !important;
    padding-top: 0 !important;
    top: -1px;
    position: relative;
  }
  .check_box_selected_xs_container {
    max-width: 50px;
    min-width: 50px;
    height: 24px;
  }
  .sum-title {
    padding-left: 59px;
  }
  .pay_sum {
    width: 80%;
    padding-top: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 5px !important;

    &::v-deep input[type='number'] {
      -moz-appearance:textfield;
    }
    &::v-deep input::-webkit-outer-spin-button,
    &::v-deep input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &::v-deep .v-input__control {
      min-height: 32px !important;
    }
    &::v-deep input {
      padding-left: 3px;
      padding-bottom: 5px;
    }
  }
  .pay_sum_sm {
    width: auto;
    margin-top: 5px !important;
  }
  .pay_sum_xs {
    padding-left: 50px;
    padding-right: 0 !important;
  }
  .pay_sum_xs_ml {
    margin-top: 5px;
    margin-left: 50px;
  }
  .pay_sum_max_width {
    max-width: 164px !important;
  }
  .meter_sum {
    padding-top: 0 !important;
    margin-top: 0 !important;

    &::v-deep input {
      padding-left: 3px;
    }
  }
  .meter_sum_xs {
    width: 97%;
    margin-bottom: 0 !important;
    margin-left: 6px !important;
    margin-right: 1px !important;
  }
  .services-exp-panel {
    &::v-deep .v-expansion-panel::before {
      box-shadow: none;
    }
  }
  .pay-amount-title {
    padding-left: 1px;
    letter-spacing: 0.06em;
    font-size: 1.35em;
  }
  .no-transition {
    &::v-deep .expand-transition-leave {
      transition: none !important;
    }
    &::v-deep .expand-transition-enter {
      transition: none !important;
    }
    &::v-deep .expand-transition-enter-to {
      transition: none !important;
    }
    &::v-deep .expand-transition-leave-to {
      transition: none !important;
    }
  }
  .organization-details:hover {
    color: green;
    transition: 0.3s;
  }
  .pointer {
    cursor: pointer;
    border-bottom: 1px solid #6dbe70;
    transition: .3s;
  }
  .payer-hover:hover {
    color: green !important;
    transition: .3s;
  }
</style>
