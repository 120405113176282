<template>
    <v-row dense align="center" justify="space-between" class="text-md-right text-sm-right py-0 pr-4">
      <v-col cols="12" md="6" sm="7" xs="12">
        <v-row no-gutters align="center" :class="`${$vuetify.breakpoint.smAndUp ? 'flex-nowrap' : ''}`">
                            <span class="hidden-xs-only">
                              <v-checkbox hide-details
                                          color="success darken-1"
                                          class="check_box_selected pl-4 pr-2 my-0"
                                          v-model.lazy="selected"
                                          :disabled="hidden"
                                          @change="updateRow"
                              />
                            </span>
          <span class="hidden-xs-only">
                              <i :class="`${getIcon(config)} pr-3`"/>
                            </span>
          <div class="text-md-left text-sm-left hidden-xs-only">
                              <span class="d-block font-weight-regular grey--text text--darken-3">
                                <span @click.stop="showDetails = true" class="organization-details">{{ organization.name }}</span><br>
                                <span class="d-block font-weight-light body-2 mt-1 mb-0">{{ service_name }}{{ person_id_internal_text ? ', о/р ' + person_id_internal_text : '' }}</span>
                              </span>
            <organizationDetails
              v-model="showDetails"
              :service_name="service_name"
              :organization="organization"
              :parentDialog="showDetails"
              :useParentDialog="true">
            </organizationDetails>
          </div>
          <v-col cols="3" xs="3" class="hidden-sm-and-up check_box_selected_xs_container">
                              <span class="d-inline-flex">
                                <v-checkbox hide-details
                                            color="success darken-1"
                                            v-model="selected"
                                            class="check_box_selected_xs pt-2 my-0">
                                </v-checkbox>
                              </span>
            <span class="d-inline-flex">
                                <i :class="`${getIcon(config)} pl-2`" :style="$vuetify.breakpoint.xsOnly ? 'position: relative; top: 10px;' : ''"/>
                              </span>
          </v-col>
          <v-col cols="9" xs="9" class="hidden-sm-and-up">
                              <span class="d-block font-weight-regular grey--text text--darken-3 pb-1">
                                {{ organization.name }}
                              </span>
          </v-col>
          <v-col cols="8" xs="8" class="hidden-sm-and-up ml-auto">
            <span class="d-block font-weight-light body-2 mt-1 mb-0 pay_sum_xs">{{ service_name }}</span>
          </v-col>
          <v-col cols="4" xs="8" class="hidden-sm-and-up ml-auto">
            <span class="d-block font-weight-light body-2 text-right text-md-left text-sm-left text-lg-left">{{ person_id_internal_text }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="2" sm="5" xs="12">
        <v-row no-gutters align="center" justify="space-between">
          <v-col cols="6" md="6" sm="6" xs="8"
                 class="text-sm-right"
                 :class="`${$vuetify.breakpoint.xsOnly ? 'pay_sum_xs' : ''}`"
          >{{ getMonth(date, service_month_difference) }}
          </v-col>
          <v-col cols="6" md="6" sm="6" xs="4" class="text-right"
                 :class="`${addClassToNumber(due)} font-weight-medium`"
          >{{ due | hrn }}</v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="2" sm="7" xs="12"
             class="text-right"
             :class="`${$vuetify.breakpoint.xsOnly ? 'mr-4 mt-2' : ''}`">
        <pay-input :sum="sum" :selected_origin="selected" :hidden="hidden" :service="service_id" :index="objIndex" @changePayInput="sum = $event"/>
      </v-col>
      <v-col cols="12" md="2" sm="5" xs="12"
             class="text-md-center text-sm-left"
             :class="`${$vuetify.breakpoint.xsOnly ? 'pay_sum_xs' : ''}`">
        <v-container fluid class="grid-list-sm ma-0 py-0 px-1">
          <v-row dense justify="center">
            <v-col cols="4" md="3" xs="4" class="text-md-right">
              <v-tooltip top color="success">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :disabled="((meters_readings) ? !meters_readings.length > 0 : true) || hidden || !isAuthenticated || !use_meters"
                    color="success"
                    :text="$vuetify.breakpoint.mdAndUp"
                    class="body-2"
                    :class="`${$vuetify.breakpoint.xsOnly ? 'my-2' : ''}`"
                    :block="$vuetify.breakpoint.smAndDown"
                    :tile="$vuetify.breakpoint.mdAndUp"
                    :large="$vuetify.breakpoint.mdAndUp"
                    :icon="$vuetify.breakpoint.mdAndUp">
                    <v-icon>mdi-speedometer</v-icon>
                  </v-btn>
                </template>
                <span>Ввести покази лічильника(-ів)</span>
              </v-tooltip>
            </v-col>
            <v-col cols="4" md="3" xs="4" >
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="primary"
                    :text="$vuetify.breakpoint.mdAndUp"
                    class="body-2"
                    :class="`${$vuetify.breakpoint.xsOnly ? 'my-2' : ''}`"
                    :block="$vuetify.breakpoint.smAndDown"
                    :tile="$vuetify.breakpoint.mdAndUp"
                    :large="$vuetify.breakpoint.mdAndUp"
                    :icon="$vuetify.breakpoint.mdAndUp"
                    @click="edit = !edit">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Вказати особовий рахунок</span>
              </v-tooltip>
            </v-col>
            <v-col cols="4" md="3" xs="4" class="text-md-left">
              <v-tooltip top :color="hidden ? 'success' : 'error' ">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :color="hidden ? 'success' : 'error' "
                    :text="$vuetify.breakpoint.mdAndUp"
                    class="body-2"
                    :class="`${$vuetify.breakpoint.xsOnly ? 'my-2' : ''}`"
                    :block="$vuetify.breakpoint.smAndDown"
                    :tile="$vuetify.breakpoint.mdAndUp"
                    :large="$vuetify.breakpoint.mdAndUp"
                    :icon="$vuetify.breakpoint.mdAndUp"
                    @click="delete_ = !delete_">
                    <v-icon>mdi-delete-empty</v-icon>
                  </v-btn>
                </template>
                <span v-if="!hidden">Приховати рядок із послугою</span>
                <span v-else>Показати рядок із послугою</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
        <editDialog
          v-model="edit"
          :person_id_internal_origin="person_id_internal_text"
          :service_name="service_name"
          :hidden_origin="hidden"
          :parentDialog="edit"
          :useParentDialog="true"
          @editPersonIdInternal="finishEditPersonIdInternal">
        </editDialog>
        <confirmDialog v-if="!hidden" @confirmModal="hideServiceRow" :index="index" v-model="delete_" :useParentDialog="true" :parentDialog="delete_" :showButton="false" :divider="false" :headerColor="'error white--text mb-1'">
          <template v-slot:buttonName>
          </template>
          <template v-slot:header>
            Вилучити послугу із списку?
          </template>
          <template v-slot:content >Послуга <span class="font-weight-bold subtitle-1">{{ service_name }}</span> після вилучення стає не активною, при необхідності Ви зможете її відновити</template>
        </confirmDialog>
        <confirmDialog v-else @confirmModal="hideServiceRow" :index="index" v-model="delete_" :useParentDialog="true" :parentDialog="delete_" :showButton="false" :divider="false" :agreeColor="'success'" :cancelColor="'grey'" :headerColor="'success white--text mb-1'">
          <template v-slot:buttonName>
          </template>
          <template v-slot:header>
            Відновити послугу?
          </template>
          <template v-slot:content >Послуга <span class="font-weight-bold subtitle-1">{{ service_name }}</span> знову стане доступною для оплати</template>
          <template v-slot:cancel>
            відмінити
          </template>
          <template v-slot:agree>
            Відновити
          </template>
        </confirmDialog>
      </v-col>
    </v-row>
</template>

<script>
  import AccountAPI from '@/api/account'

  import {getIcon, addClassToNumber, cols } from "@/utils";
  import { UPDATE_DATA } from '../../store/modules/detail/mutation-types'

  import editDialog from "@/components/global/DetailEdit";
  import payInput from "@/components/global/PayInput"
  import organizationDetails from "@/components/global/OrganizationDetails";
  import confirmDialog from "@/components/global/confirmDialog";

    export default {
      name: "DetailViewRow",
      props: ['rowItem', 'rowIndex', 'isAuthenticatedView', 'secret_id', 'showHidden'],
      data() {
        return {
          selected: this.rowItem.selected,
          hidden: this.rowItem.hidden,
          delete_: this.rowItem.delete,
          edit: this.rowItem.edit,
          config: this.rowItem.service.config,
          organization: this.rowItem.service.organization,
          objIndex: this.rowIndex,
          service_name: this.rowItem.service.name,
          service_id: this.rowItem.service.id,
          service_month_difference: this.rowItem.service.month_difference,
          person_id_internal_text: this.rowItem.person_id_internal_text,
          person_id_internal: this.rowItem.person_id_internal,
          showDetails: false,
          date: this.rowItem.date,
          due: this.rowItem.due,
          sum: this.rowItem.sum,
          meters_readings: this.rowItem.meters_readings,
          use_meters: false,
        }
      },
      computed: {
        isAuthenticated() {
          return this.$store.getters.isAuthenticated;
        },
      },
      methods: {
        async UpdateService(payload) {
          try {
            await AccountAPI.accountServiceUpdate(payload);
          } catch (e) {
            this.$snackbar("Помилка оновлення даних по послузі", 'error')
          }
        },
        updateRow() {
          let obj = {
            selected: this.selected,
            hidden: this.hidden,
            delete: this.delete_,
            edit: this.edit,
            person_id_internal_text: this.person_id_internal_text,
            sum: this.sum,
            id: this.objIndex
          }
          this.$store.dispatch(UPDATE_DATA, obj);
        },
        getMonth(date, difference = 0) {
          return this.$moment(date, 'YYYY-MM-DD').add(difference, 'month').format('MMMM');
        },
        finishUpdateService() {
          const SERVICE = {};

          SERVICE.secret_id = this.secret_id;
          SERVICE.service_id = this.service_id;
          SERVICE.person_id_internal = this.person_id_internal;

          if (this.person_id_internal_text !== null) {
            SERVICE.person_id_internal_text = this.person_id_internal_text;
          }
          SERVICE.hidden = this.hidden;

          this.UpdateService(SERVICE);
        },
        finishEditPersonIdInternal(payload) {
          if (this.person_id_internal_text !== payload.person_id_internal) {
            this.person_id_internal_text = payload.person_id_internal;
            this.edit = false;
            this.hidden = payload.hidden;

            if (this.isAuthenticatedView) {
              this.finishUpdateService(payload);
            }
            this.updateRow();
          }
        },
        hideServiceRow(value) {
          if (value.question) {
            this.hidden = !this.hidden;
            this.delete = false;
            this.selected = false;
            this.edit = false;
            this.sum = 0;

            if (this.isAuthenticatedView) {
              this.finishUpdateService(value);
            }

            // this.panel = null;
          }
        },
        // showAccordeonPanel(idx) {
        //   this.$snackbar(idx, 'error')
        // },
        getIcon,
        addClassToNumber,
        cols
      },
      watch: {
        sum() {
          if (!this.selected && this.sum) {
            this.selected = true;
          }
          this.updateRow();
        },
        hidden() {
          this.updateRow();
        },
        delete_() {
          this.updateRow();
        },
        // selected() {
        //   console.log('selected');
        //   debugger
        //   this.updateRow();
        // },
        'rowItem.selected': function (newVal, oldVal) {
          if (newVal !== oldVal) {
            this.selected = newVal;
          }
        }
      },
      components: {editDialog, payInput, organizationDetails, confirmDialog},
      beforeDestroy() {
        this.selected = false;
        this.hidden = false;
        this.config = {};
        this.organization = {};
        this.objIndex = -1;
        this.service_name = '';
        this.service_id = 0;
        this.service_month_difference = -1;
        this.person_id_internal_text = '';
        this.person_id_internal = 0;
        this.date = '';
        this.due = 0;
        this.sum = 0;
        this.meters_readings = [];
      }
    }
</script>

<style scoped lang="scss">
  a {
    text-decoration: none;
  }
  .font-icon{
    font-size: 22px;
    padding-right: 28px;
    position: relative;
  }
  .check_box_selected {
    padding-top: 0 !important;
  }
  .check_box_selected_xs {
    width: 20px;
    padding-left: 7px !important;
    margin-right: 8px !important;
    padding-top: 0 !important;
    top: -1px;
    position: relative;
  }
  .check_box_selected_xs_container {
    max-width: 50px;
    min-width: 50px;
    height: 24px;
  }
  .pay_sum {
    width: 80%;
    padding-top: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 5px !important;

    &::v-deep input[type='number'] {
      -moz-appearance:textfield;
    }
    &::v-deep input::-webkit-outer-spin-button,
    &::v-deep input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &::v-deep .v-input__control {
      min-height: 32px !important;
    }
    &::v-deep input {
      padding-left: 3px;
      padding-bottom: 5px;
    }
  }
  .pay_sum_sm {
    width: auto;
    margin-top: 5px !important;
  }
  .pay_sum_xs {
    padding-left: 50px;
    padding-right: 0 !important;
  }
  .pay_sum_xs_ml {
    margin-top: 5px;
    margin-left: 50px;
  }
  .pay_sum_max_width {
    max-width: 164px !important;
  }
  .no-transition {
    &::v-deep .expand-transition-leave {
      transition: none !important;
    }
    &::v-deep .expand-transition-enter {
      transition: none !important;
    }
    &::v-deep .expand-transition-enter-to {
      transition: none !important;
    }
    &::v-deep .expand-transition-leave-to {
      transition: none !important;
    }
  }
  .organization-details:hover {
    color: green;
    transition: 0.3s;
  }
  .pointer {
    cursor: pointer;
    border-bottom: 1px solid #6dbe70;
    transition: .3s;
  }
  .payer-hover:hover {
    color: green !important;
    transition: .3s;
  }
</style>
